<template>
  <div>
    <title>SIM INVENTORY ~ PROCESS LIST DATA UPDATE</title>
    <section class="content-header">
      <h1>
        Process List Data Update
        <br />
        <h4>Please edit the process list master data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Process List Master</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Process List Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Code </label>
                  <input
                    type="text"
                    v-model="kodeproses"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idproses"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Name</label>
                  <input
                    type="text"
                    v-model="namaproses"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="notesproses"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/daftar-proses">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "editmasterdata",
  data() {
    return {
      isInputActive: false,
      loading: false,
      kodeproses: "",
      namaproses: "",
      idproses: "",
      notesproses: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_proses/getdaftar_prosesbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "daftar_proses/getdaftar_prosesbyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          this.idproses = resp.data.data.id;
          this.kodeproses = resp.data.data.kode_proses;
          this.namaproses = resp.data.data.nama_proses;
          this.notesproses = resp.data.data.notes;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kodeproses = this.kodeproses;
      var namaproses = this.namaproses;
      var notes = this.notesproses;
      var idx = this.idproses;
      if (kodeproses == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (namaproses == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notes == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_proses: this.kodeproses,
          nama_proses: this.namaproses,
          notes: this.notesproses,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/daftar_proses/updatedaftar_proses/" + idx;
        const urlAPIUpdate =
          this.$apiurl + "daftar_proses/updatedaftar_proses/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "daftarproses" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "daftarproses" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
